import { default as _91_46_46_46cms_93NMA8IvhJI1Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93ze1xNr7lz8Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/3d-projects/[...slug].vue?macro=true";
import { default as indexaHC50ULrwzMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/3d-projects/index.vue?macro=true";
import { default as _91_46_46_46slug_933nlKjhLU2kMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/akcii/[...slug].vue?macro=true";
import { default as indexsxjRek5THLMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/akcii/index.vue?macro=true";
import { default as _91_46_46_46slug_93eIoMCbtkabMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/articles/[...slug].vue?macro=true";
import { default as indexrf468cdjDQMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/articles/index.vue?macro=true";
import { default as indexwejQMT3ncsMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/authorization/index.vue?macro=true";
import { default as indexR1uTjuhkqrMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/cart/index.vue?macro=true";
import { default as index0XpqrkFeELMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/cart/success/index.vue?macro=true";
import { default as _91_46_46_46slug_93hrbBNHxKayMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/catalog/[...slug].vue?macro=true";
import { default as indexEsZihmWdzJMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/catalog/index.vue?macro=true";
import { default as _91_46_46_46slug_93o3Yj1720dDMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/catalogsearch/[...slug].vue?macro=true";
import { default as result3QWMsytE3kMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/catalogsearch/result.vue?macro=true";
import { default as _91_46_46_46slug_93uRU8RisboCMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/collection/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93D5A8A95JkLMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/fabriki/[...slug].vue?macro=true";
import { default as indexMNBbkDuGUPMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/fabriki/index.vue?macro=true";
import { default as _91_46_46_46slug_93elVrCaEKIzMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/product/[...slug].vue?macro=true";
import { default as indexZ2Tfi3qV57Meta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/profile/index.vue?macro=true";
import { default as indexCO76xIV6uKMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/profile/orders/index.vue?macro=true";
import { default as index06T0t5V5lmMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/recently/index.vue?macro=true";
import { default as _91_46_46_46slug_93wVBRl9MSjpMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/sets/[...slug].vue?macro=true";
import { default as indexccbEbD2pYsMeta } from "/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93NMA8IvhJI1Meta?.name ?? "cms",
    path: _91_46_46_46cms_93NMA8IvhJI1Meta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93NMA8IvhJI1Meta || {},
    alias: _91_46_46_46cms_93NMA8IvhJI1Meta?.alias || [],
    redirect: _91_46_46_46cms_93NMA8IvhJI1Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ze1xNr7lz8Meta?.name ?? "3d-projects-slug",
    path: _91_46_46_46slug_93ze1xNr7lz8Meta?.path ?? "/3d-projects/:slug(.*)*",
    meta: _91_46_46_46slug_93ze1xNr7lz8Meta || {},
    alias: _91_46_46_46slug_93ze1xNr7lz8Meta?.alias || [],
    redirect: _91_46_46_46slug_93ze1xNr7lz8Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/3d-projects/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexaHC50ULrwzMeta?.name ?? "3d-projects",
    path: indexaHC50ULrwzMeta?.path ?? "/3d-projects",
    meta: indexaHC50ULrwzMeta || {},
    alias: indexaHC50ULrwzMeta?.alias || [],
    redirect: indexaHC50ULrwzMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/3d-projects/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_933nlKjhLU2kMeta?.name ?? "akcii-slug",
    path: _91_46_46_46slug_933nlKjhLU2kMeta?.path ?? "/akcii/:slug(.*)*",
    meta: _91_46_46_46slug_933nlKjhLU2kMeta || {},
    alias: _91_46_46_46slug_933nlKjhLU2kMeta?.alias || [],
    redirect: _91_46_46_46slug_933nlKjhLU2kMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/akcii/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexsxjRek5THLMeta?.name ?? "akcii",
    path: indexsxjRek5THLMeta?.path ?? "/akcii",
    meta: indexsxjRek5THLMeta || {},
    alias: indexsxjRek5THLMeta?.alias || [],
    redirect: indexsxjRek5THLMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/akcii/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93eIoMCbtkabMeta?.name ?? "articles-slug",
    path: _91_46_46_46slug_93eIoMCbtkabMeta?.path ?? "/articles/:slug(.*)*",
    meta: _91_46_46_46slug_93eIoMCbtkabMeta || {},
    alias: _91_46_46_46slug_93eIoMCbtkabMeta?.alias || [],
    redirect: _91_46_46_46slug_93eIoMCbtkabMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/articles/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexrf468cdjDQMeta?.name ?? "articles",
    path: indexrf468cdjDQMeta?.path ?? "/articles",
    meta: indexrf468cdjDQMeta || {},
    alias: indexrf468cdjDQMeta?.alias || [],
    redirect: indexrf468cdjDQMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexwejQMT3ncsMeta?.name ?? "authorization",
    path: indexwejQMT3ncsMeta?.path ?? "/authorization",
    meta: indexwejQMT3ncsMeta || {},
    alias: indexwejQMT3ncsMeta?.alias || [],
    redirect: indexwejQMT3ncsMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexR1uTjuhkqrMeta?.name ?? "cart",
    path: indexR1uTjuhkqrMeta?.path ?? "/cart",
    meta: indexR1uTjuhkqrMeta || {},
    alias: indexR1uTjuhkqrMeta?.alias || [],
    redirect: indexR1uTjuhkqrMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: index0XpqrkFeELMeta?.name ?? "cart-success",
    path: index0XpqrkFeELMeta?.path ?? "/cart/success",
    meta: index0XpqrkFeELMeta || {},
    alias: index0XpqrkFeELMeta?.alias || [],
    redirect: index0XpqrkFeELMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/cart/success/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93hrbBNHxKayMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93hrbBNHxKayMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93hrbBNHxKayMeta || {},
    alias: _91_46_46_46slug_93hrbBNHxKayMeta?.alias || [],
    redirect: _91_46_46_46slug_93hrbBNHxKayMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexEsZihmWdzJMeta?.name ?? "catalog",
    path: indexEsZihmWdzJMeta?.path ?? "/catalog",
    meta: indexEsZihmWdzJMeta || {},
    alias: indexEsZihmWdzJMeta?.alias || [],
    redirect: indexEsZihmWdzJMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93o3Yj1720dDMeta?.name ?? "catalogsearch-slug",
    path: _91_46_46_46slug_93o3Yj1720dDMeta?.path ?? "/catalogsearch/:slug(.*)*",
    meta: _91_46_46_46slug_93o3Yj1720dDMeta || {},
    alias: _91_46_46_46slug_93o3Yj1720dDMeta?.alias || [],
    redirect: _91_46_46_46slug_93o3Yj1720dDMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/catalogsearch/[...slug].vue").then(m => m.default || m)
  },
  {
    name: result3QWMsytE3kMeta?.name ?? "catalogsearch-result",
    path: result3QWMsytE3kMeta?.path ?? "/catalogsearch/result",
    meta: result3QWMsytE3kMeta || {},
    alias: result3QWMsytE3kMeta?.alias || [],
    redirect: result3QWMsytE3kMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/catalogsearch/result.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93uRU8RisboCMeta?.name ?? "collection-slug",
    path: _91_46_46_46slug_93uRU8RisboCMeta?.path ?? "/collection/:slug(.*)*",
    meta: _91_46_46_46slug_93uRU8RisboCMeta || {},
    alias: _91_46_46_46slug_93uRU8RisboCMeta?.alias || [],
    redirect: _91_46_46_46slug_93uRU8RisboCMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/collection/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93D5A8A95JkLMeta?.name ?? "fabriki-slug",
    path: _91_46_46_46slug_93D5A8A95JkLMeta?.path ?? "/fabriki/:slug(.*)*",
    meta: _91_46_46_46slug_93D5A8A95JkLMeta || {},
    alias: _91_46_46_46slug_93D5A8A95JkLMeta?.alias || [],
    redirect: _91_46_46_46slug_93D5A8A95JkLMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/fabriki/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexMNBbkDuGUPMeta?.name ?? "fabriki",
    path: indexMNBbkDuGUPMeta?.path ?? "/fabriki",
    meta: indexMNBbkDuGUPMeta || {},
    alias: indexMNBbkDuGUPMeta?.alias || [],
    redirect: indexMNBbkDuGUPMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/fabriki/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93elVrCaEKIzMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93elVrCaEKIzMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93elVrCaEKIzMeta || {},
    alias: _91_46_46_46slug_93elVrCaEKIzMeta?.alias || [],
    redirect: _91_46_46_46slug_93elVrCaEKIzMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexZ2Tfi3qV57Meta?.name ?? "profile",
    path: indexZ2Tfi3qV57Meta?.path ?? "/profile",
    meta: indexZ2Tfi3qV57Meta || {},
    alias: indexZ2Tfi3qV57Meta?.alias || [],
    redirect: indexZ2Tfi3qV57Meta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexCO76xIV6uKMeta?.name ?? "profile-orders",
    path: indexCO76xIV6uKMeta?.path ?? "/profile/orders",
    meta: indexCO76xIV6uKMeta || {},
    alias: indexCO76xIV6uKMeta?.alias || [],
    redirect: indexCO76xIV6uKMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/profile/orders/index.vue").then(m => m.default || m)
  },
  {
    name: index06T0t5V5lmMeta?.name ?? "recently",
    path: index06T0t5V5lmMeta?.path ?? "/recently",
    meta: index06T0t5V5lmMeta || {},
    alias: index06T0t5V5lmMeta?.alias || [],
    redirect: index06T0t5V5lmMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/recently/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93wVBRl9MSjpMeta?.name ?? "sets-slug",
    path: _91_46_46_46slug_93wVBRl9MSjpMeta?.path ?? "/sets/:slug(.*)*",
    meta: _91_46_46_46slug_93wVBRl9MSjpMeta || {},
    alias: _91_46_46_46slug_93wVBRl9MSjpMeta?.alias || [],
    redirect: _91_46_46_46slug_93wVBRl9MSjpMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/sets/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexccbEbD2pYsMeta?.name ?? "wishlist",
    path: indexccbEbD2pYsMeta?.path ?? "/wishlist",
    meta: indexccbEbD2pYsMeta || {},
    alias: indexccbEbD2pYsMeta?.alias || [],
    redirect: indexccbEbD2pYsMeta?.redirect,
    component: () => import("/var/www/new.mia-sofia.ru/miasofiaru-front-20240916101026/pages/wishlist/index.vue").then(m => m.default || m)
  }
]